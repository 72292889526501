<template>
  <div class="app-bar">
    <i
      class="fas fa-home HomeIcon"
      @click="
        if (!Settings) {
          $emit('MoveToHomePage');
        }
      "
    ></i>

    <div v-if="Settings" class="Ueberschrift">Einstellung</div>
    <div v-if="CurrentPage == 'Quiz' && !Settings" class="Ueberschrift">
      Quiz
    </div>
    <div v-if="CurrentPage == 'QuizOverVUE' && !Settings" class="Ueberschrift">
      Übersicht
    </div>
    <div v-if="CurrentPage == 'Uebersicht' && !Settings" class="Ueberschrift">
      Übersicht
    </div>
    <div v-if="CurrentPage == 'HomePage' && !Settings"></div>

    <div style="float: right; display: none">
      <i
        class="fas fa-bars SettingsIcon"
        :class="[Settings ? 'fa-rotate-90' : '']"
        @click="[Settings ? SetSettingsFalse() : SetSettingsTrue()]"
      >
      </i>
    </div>
  </div>
  <div v-if="Settings" class="SettingsBackground">
    <div class="Settings-Li">
      <div class="Li-Element">
        <div
          :key="HapticsKey"
          class="Haptics"
          @click="this.toggelHaptics()"
          :class="[!!this.getHaptics() ? 'BorderGreen' : 'BorderRed']"
        >
          <div class="info" v-if="!!this.getHaptics()">Vibration An</div>
          <div class="info" v-else>Vibration Aus</div>
        </div>
      </div>
      <div class="Li-Element">
        <div class="info Haptics BorderGray" @click="this.giveSomeInfo()">
          Developer Info
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.app-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(0, 66, 127);
}

.info {
  height: inherit;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BorderRed {
  border-color: red;
}

.BorderGreen {
  border-color: green;
}

.BorderGray {
  border-color: rgb(131, 130, 130);
}

.Haptics {
  height: 2rem;
  border-left-style: solid;
  border-left-width: 1rem;
}

.SettingsBackground {
  background-color: rgb(240, 240, 240);
  width: 100vw;
  z-index: 2;
  height: calc(100vh - 6rem);
  top: 4rem;
}

.Li-Element {
  height: 2rem;
  color: rgb(131, 130, 130);
}

.Settings-Li :nth-child(2n + 0) {
  background-color: lightgray;
  color: white;
}

.DropEnabled {
  display: block;
}

.HomeIcon {
  font-size: 2.5rem;
  color: white;
  margin: 0.5rem;
}

.SettingsIcon {
  font-size: 3rem;
  color: white;
  margin: 0.5rem;
}

.Ueberschrift {
  font-size: 2rem;
  padding: 0.5em;
  color: white;
}
</style>

<script>
import { Haptics, ImpactStyle } from "@capacitor/haptics";

export default {
  name: "Navbar",
  props: {
    LastPage: String,
    CurrentPage: String,
    SettingsString: String,
  },
  data() {
    return {
      Settings: false,
      Filter: "*",
      HapticsKey: 0,
    };
  },
  components: {},
  methods: {
    toggelHaptics() {
      if (this.getHaptics()) {
        this.HapticsKey++;

        localStorage.setItem("Haptics", false);
      } else {
        this.HapticsKey--;
        Haptics.impact(ImpactStyle.Light);
        localStorage.setItem("Haptics", true);
      }
    },
    giveSomeInfo() {
      let viewPort = this.getViewport();
      var output =
        "INFO" +
        "\nGerätbreite: " +
        viewPort[0] +
        "\nGeräthöhe: " +
        viewPort[1] +
        "\n Version: 1.1.0";
      window.alert(output);
    },
    getViewport() {
      var viewPortWidth;
      var viewPortHeight;

      // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
      if (typeof window.innerWidth != "undefined") {
        (viewPortWidth = window.innerWidth),
          (viewPortHeight = window.innerHeight);
      }

      // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
      else if (
        typeof document.documentElement != "undefined" &&
        typeof document.documentElement.clientWidth != "undefined" &&
        document.documentElement.clientWidth != 0
      ) {
        (viewPortWidth = document.documentElement.clientWidth),
          (viewPortHeight = document.documentElement.clientHeight);
      }

      // older versions of IE
      else {
        (viewPortWidth = document.getElementsByTagName("body")[0].clientWidth),
          (viewPortHeight =
            document.getElementsByTagName("body")[0].clientHeight);
      }
      return [viewPortWidth, viewPortHeight];
    },
    SetSettingsFalse() {
      this.Settings = false;
      this.$emit("SettingsFalse");
    },
    SetSettingsTrue() {
      this.Settings = true;
      this.$emit("SettingsTrue");
    },
    setSelection(value) {
      console.log("selection:", value);
      localStorage.setItem("CurrentFilter", value);
    },
    getHaptics() {
      if (localStorage.getItem("Haptics") === "true") {
        return true;
      } else return false;
    },
  },
  emits: [
    "MoveToEinstellung",
    "MoveToHomePage",
    "MoveToQuiz",
    "MoveToUebersicht",
    "SettingsTrue",
    "SettingsFalse",
  ],
};
</script>
