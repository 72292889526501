<template>
  <div class="QuizFlx">
    <div>
      <div v-show="!this.Firstload" class="titel">
        {{ this.HeadLine }}
      </div>
    </div>
    <div class="QuestionHolder">
      <div class="CardHolder">
        <div v-if="this.Firstload" class="center">
          <div class="Nummer">
            {{ this.GetSummeFragen() }}
          </div>
          Punkte
        </div>
        <div v-if="!this.Firstload">
          <p style="color: #00427f"></p>
          <img
            class="Bild"
            v-bind:src="
              require(`../assets/images/signals/${this.BildValue}.jpg`)
            "
          />
          <p class="Frage">{{ this.Bezeichnung }}</p>
          <p class="Frage">{{ this.Bedeutung }}</p>
        </div>
      </div>
    </div>
    <div class="AnswerButtonGrid"></div>
    <button
      v-if="this.Firstload"
      @click="this.Firstload = false"
      class="btn btn-primary btn-weiter"
    >
      Weiter
    </button>
    <button
      v-if="!this.Firstload"
      @click="Weiter()"
      class="btn btn-primary btn-weiter"
    >
      Weiter
    </button>
  </div>
</template>
<style scoped>
@import "../../public/edith-app.css";
.AnswerButtonGrid {
}
.center {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
}
.titel {
  margin: 5px;
  padding: 0.5rem;
  background-color: gray;
  color: white;
  border-radius: 0.2rem;
}

.QuizFlx {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.QuestionHolder {
  width: 90%;
  max-width: 60vh;
  pointer-events: none;
}

img.Bild {
  width: 100%;
}

.CardHolder {
  width: 100%;
  border-radius: 0.3rem;
  background-color: white;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1rem;
}

.Frage {
  color: black;
  font-size: 0.7rem;
  justify-content: center;
}

.Nummer {
  font-size: 12rem;
  color: rgb(0, 66, 127);
  font-weight: 600;
}
</style>

<script>
//import CurrentMode from './CurrentMode.json'
import Signale from "../assets/signals.json";
//import Signale from './SignaleSort.json'

export default {
  data() {
    return {
      Firstload: true,
      Nummer: 1,
      Bezeichnung: "",
      Bedeutung: "",
      BildValue: "",
      HeadLine: this.GetText(),
      keineFehler: false,
    };
  },
  methods: {
    Delete() {
      localStorage.setItem("Fehler1", "");
      localStorage.setItem("Fehler2", "");
      localStorage.setItem("Fehler3", "");
      localStorage.setItem("Fehler4", "");
      localStorage.setItem("FehlerSumme", "0");
      this.$emit("MoveToQuiz");
    },
    GetText() {
      if (0 == localStorage.getItem("FehlerSumme")) {
        this.keineFehler = true;
        return "Sehr gut! Keine Fehler!";
      } else {
        return "Übersicht der falsch beantworteten Fragen";
      }
    },
    GetSummeFragen() {
      let FragenMinusFehler =
        localStorage.getItem("FragenRichtig") -
        localStorage.getItem("FehlerSumme");
      if (FragenMinusFehler < 0) {
        return 0;
      }
      return FragenMinusFehler;
    },

    Weiter() {
      if (this.Nummer < localStorage.getItem("FehlerSumme")) {
        this.Nummer++;

        if (this.Nummer == undefined) {
          this.Nummer = 1;
        }

        this.Bezeichnung = this.getAttribute("Bezeichnung");
        this.Bedeutung = this.getAttribute("Bedeutung");
        this.BildValue = this.getAttribute("Bild");
      } else {
        this.Delete();
      }
    },
    getAttribute(Attribute) {
      let $Attribut = Attribute;

      if (this.Nummer == undefined) {
        this.Nummer = 1;
      }
      let $str = "Fehler" + this.Nummer;
      let $id = localStorage.getItem($str);
      let $list = Signale.filter(
        (Kriterium) => Kriterium.LaufendeNummer == $id
      );
      console.debug($list);
      if ($list.length == 0) {
        if (Attribute == "Bild") {
          return "000";
        } else {
          return null;
        }
      }
      console.log($list[0][$Attribut]);
      return $list[0][$Attribut];
    },
  },
  components: {},
  created() {
    this.Bezeichnung = this.getAttribute("Bezeichnung");
    this.Bedeutung = this.getAttribute("Bedeutung");
    this.BildValue = this.getAttribute("Bild");
  },
};
</script>
