<template>
  <div class="QuizFlx">
    <div id="progressbar"><div id="bar"></div></div>
    <div class="QuestionHolder">
      <div class="CardHolder">
        <div>
          <div class="hearts">
            <i
              class="icon-abstand"
              :class="[
                SummeFalscherAntworten > 2
                  ? 'animate__animated animate__headShake far fa-heart'
                  : 'animate__animated animate__bounceInDown delay400ms fas fa-heart',
              ]"
            ></i>
            <i
              class="icon-abstand"
              :class="[
                SummeFalscherAntworten > 1
                  ? 'animate__animated animate__headShake far fa-heart'
                  : 'animate__animated animate__bounceInDown fas fa-heart',
              ]"
            ></i>
            <i
              class="icon-abstand"
              :class="[
                SummeFalscherAntworten > 0
                  ? 'animate__animated animate__headShake far fa-heart'
                  : 'animate__animated animate__bounceInDown delay200ms fas fa-heart',
              ]"
            ></i>
          </div>
          <p v-show="SummeFalscherAntworten > 3" style="color: #00427f"></p>
          <img
            class="Bild"
            v-bind:src="require(`../assets/images/signals/${BildId}.jpg`)"
          />
        </div>

        <div class="Frage" v-show="FragenSindSichtbar">
          {{ FragenText }}
        </div>
        <div
          class="HinweisBox"
          :class="Richtig ? 'Gruen' : 'Rot animate__animated animate__pulse'"
          v-show="!FragenSindSichtbar"
        >
          {{ HinweisText }}
        </div>
      </div>
    </div>
    <div class="AnswerButtonGrid" v-show="FragenSindSichtbar">
      <button class="btn btn-primary" @click="getVisibility(Button1)">
        {{ Button1 }}
      </button>
      <button class="btn btn-primary" @click="getVisibility(Button2)">
        {{ Button2 }}
      </button>
      <button class="btn btn-primary" @click="getVisibility(Button3)">
        {{ Button3 }}
      </button>
      <button class="btn btn-primary" @click="getVisibility(Button4)">
        {{ Button4 }}
      </button>
    </div>
    <button
      v-show="!FragenSindSichtbar"
      style="width: 100%; max-width: 30vh"
      class="btn btn-primary btn-weiter"
      @click="letsgo()"
      :class="SummeFalscherAntworten > 3 ? 'DisplayNone' : ''"
    >
      Weiter
    </button>
  </div>
</template>
<style scoped>
@import "../../public/edith-app.css";
.QuizFlx {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.QuestionHolder {
  width: 90%;
  max-width: 60vh;
  pointer-events: none;
}
.CardHolder {
  width: 100%;
  border-radius: 0.3rem;
  background-color: white;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 1rem;
}
.HinweisBox {
  justify-content: center;
  min-height: 3rem;
  padding: 1rem;
  border-radius: 0.3rem;
  font-size: 0.7rem;
}
#progressbar {
  background-color: rgb(90, 91, 85);
  padding: 3px;
  width: 100%;
}
.scroll-container {
  height: max-content;
}
#bar {
  background-color: white;
  transition: width 200ms;
  width: 0%;
  border-radius: 0 0.125rem 0.125rem 0;
  height: 0.5em;
}

img.Bild {
  width: 100%;
}
.hearts {
  flex-wrap: wrap;
  flex-direction: row;
  width: auto;
  font-size: var(--text1);
}
.icon-abstand {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: 0.2rem;
  margin-bottom: 0.25rem;
  color: #00427f;
  animation-duration: 100ms;
}
.Frage {
  color: black;
  font-size: 0.7rem;
  justify-content: center;
}
.AnswerButtonGrid {
  bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin: 0.5rem 0.5rem;
}
@media screen and (max-width: 350px) {
  .AnswerButtonGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
<script>
import { Haptics, ImpactStyle, NotificationType } from "@capacitor/haptics";

import "animate.css";
import AppVue from "../App.vue";
import CurrentMode from "../components/CurrentMode.json";
export default {
  name: "Quiz",
  props: {},
  data() {
    return {
      TEXT: ["", "", "", "", "Wir wünschen viel Erfolg!!"],
      BildId: "000",
      FragenSindSichtbar: true,
      HinweisText: "",
      Richtig: false,
      SummeFalscherAntworten: 0,
      Button1: "",
      Button2: "",
      Button3: "",
      Button4: "",
      FragenText: "",
      Fortschritt: "0",
      BarPlus: "",
    };
  },
  //delete Fortschritt at first load
  components: {},
  methods: {
    //wichtig, jedesmal neu Knöpfe fordern
    letsgo() {
      this.setMode();
      AppVue.methods.GetQuestions();
      this.Button1 = localStorage.getItem("TextButton1");
      this.Button2 = localStorage.getItem("TextButton2");
      this.Button3 = localStorage.getItem("TextButton3");
      this.Button4 = localStorage.getItem("TextButton4");
      this.FragenSindSichtbar = true;
      this.GetNewPictureLocal();
      //kann weg
      this.FragenText = localStorage.getItem("FrageText");
      //Fragen und button Seperat oder leicht verwaltbar als Array???
    },
    GetNewPictureLocal() {
      this.BildId = localStorage.getItem("Bild");
    },

    getVisibility(PressedButton) {
      switch (localStorage.getItem("Mode")) {
        case "Bezeichnung":
          if (PressedButton == localStorage.getItem("Bezeichnung")) {
            this.ReactOnTrue();
          } else {
            this.ReactOnFalse();
          }
          break;
        case "Bedeutung":
          if (PressedButton == localStorage.getItem("Bedeutung")) {
            this.ReactOnTrue();
          } else {
            this.ReactOnFalse();
          }
          break;
      }

      this.FragenSindSichtbar = false;
    },
    setMode() {
      let Rando = Math.random() * 2 + 1;

      Rando = Math.floor(Rando);

      switch (Rando) {
        case 1:
          localStorage.setItem("Mode", "Bezeichnung");
          break;
        case 2:
          localStorage.setItem("Mode", "Bedeutung");
          break;
      }
    },
    BarMovement(BarPlus) {
      if (BarPlus) {
        if (this.Fortschritt < 95) {
          this.Fortschritt = parseInt(this.Fortschritt) + 5;
        } else {
          this.Fortschritt = 0;
          this.$emit("MoveToQuizReport", this.Fortschritt);
        }
      } else {
        if (this.Fortschritt > 0) {
          this.Fortschritt = parseInt(this.Fortschritt) - 5;
        }
        CurrentMode[0].SummeFalscherAntworten =
          CurrentMode[0].SummeFalscherAntworten + 1;
      }
      var Bar = document.getElementById("bar");
      console.log(this.Fortschritt + "Prozent");
      Bar.style.width = this.Fortschritt + "%";
    },
    resetFortschritt() {
      this.Fortschritt = 0;
    },
    ReactOnTrue() {
      var Frage = parseInt(localStorage.getItem("Nummer"));
      var NewVal = parseInt(localStorage.getItem(Frage)) - 1;

      if (localStorage.getItem(localStorage.getItem("Nummer")) != null) {
        if (NewVal > 0) {
          localStorage.setItem(localStorage.getItem("Nummer"), NewVal);
        } else {
          localStorage.setItem(localStorage.getItem("Nummer"), "1");
        }
      } else {
        localStorage.setItem(localStorage.getItem("Nummer"), "8");
      }

      console.log("True");
      localStorage.FragenRichtig =
        parseFloat(localStorage.getItem("FragenRichtig")) + 1;
      this.Richtig = true;
      this.BarMovement(true);
      switch (localStorage.getItem("Mode")) {
        case "Bezeichnung":
          this.HinweisText =
            '"' +
            localStorage.getItem("Bezeichnung") +
            '"\nwar Richtig, weiter so!';
          break;

        case "Bedeutung":
          this.HinweisText =
            '"' +
            localStorage.getItem("Bedeutung") +
            '"\nwar Richtig, weiter so!';
          break;
      }
    },
    ReactOnFalse() {
      if (localStorage.getItem("Haptics") === "true") {
        console.error("Wwwrt Wwwrt");
        Haptics.impact(ImpactStyle.Light);
      }

      var Frage = parseInt(localStorage.getItem("Nummer"));
      var NewVal = parseInt(localStorage.getItem(Frage)) + 1;

      if (localStorage.getItem(localStorage.getItem("Nummer")) != null) {
        if (NewVal < 9) {
          localStorage.setItem(localStorage.getItem("Nummer"), NewVal);
        } else {
          localStorage.setItem(localStorage.getItem("Nummer"), "9");
        }
      } else {
        localStorage.setItem(localStorage.getItem("Nummer"), "9");
      }

      this.BarMovement(false);
      this.Richtig = false;
      switch (localStorage.getItem("Mode")) {
        case "Bezeichnung":
          this.HinweisText =
            'Das war leider falsch, korrekt wäre: "' +
            localStorage.getItem("Bezeichnung") +
            '"';
          break;
        case "Bedeutung":
          this.HinweisText =
            "Das war leider falsch, korrekt wäre:" +
            '\n"' +
            localStorage.getItem("Bedeutung") +
            '"';
          break;
      }
      this.SummeFalscherAntworten++;
      let FehlerSummenBuffer = parseInt(localStorage.getItem("FehlerSumme"));
      FehlerSummenBuffer++;
      localStorage.setItem("FehlerSumme", FehlerSummenBuffer);
      CurrentMode[0].SummeFalscherAntworten = this.SummeFalscherAntworten;
      switch (this.SummeFalscherAntworten) {
        case 1:
          localStorage.setItem(
            "Fehler1",
            parseInt(localStorage.getItem("Nummer"))
          );
          break;
        case 2:
          localStorage.setItem(
            "Fehler2",
            parseInt(localStorage.getItem("Nummer"))
          );
          break;
        case 3:
          localStorage.setItem(
            "Fehler3",
            parseInt(localStorage.getItem("Nummer"))
          );
          break;
        case 4:
          if (localStorage.getItem("Haptics" === "true")) {
            console.log("Wwwrt Wwwrt");
            Haptics.notification(NotificationType.Error);
          }

          localStorage.setItem(
            "Fehler4",
            parseInt(localStorage.getItem("Nummer"))
          );
          break;
      }
      if (FehlerSummenBuffer > 3) {
        this.$emit("MoveToQuizReport", this.Fortschritt);
      }
    },
  },

  created() {
    localStorage.setItem("FragenRichtig", "0");
    localStorage.setItem("Mode", "Bedeutung");
    this.letsgo();
    localStorage.setItem(
      "FrageText",
      "Wie lautet die Bezeichnung für dieses Signal?"
    );
    localStorage.setItem("FehlerSumme", "0");
    localStorage.setItem("Fehler1", "0");
    localStorage.setItem("Fehler2", "0");
    localStorage.setItem("Fehler3", "0");
    localStorage.setItem("Fehler4", "0");
    this.Fortschritt = 0;
  },

  emits: [
    "ButtonClicked",
    "QuizVariable",
    "ChosenAnswer3",
    "ChosenAnswer2",
    "ChosenAnswer1",
    "ChosenAnswer0",
    "Weiter",
  ],
};
</script>


