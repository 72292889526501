<template>
  <div style="height: 100%">
    <div style="position: absolute; z-index: 1000">
      <SplashScreen v-if="Splash" @killSplash="this.killSplash()">
      </SplashScreen>
    </div>
    <NavBar
      :CurrentPage="CurrentPage"
      :LastPage="LastPage"
      @SettingsFalse="SetSettingsFalse()"
      @SettingsTrue="SetSettingsTrue()"
      @MoveToHomePage="SetCurrentPage('HomePage')"
      @MoveToQuiz="SetCurrentPage('Quiz')"
      @MoveToUebersicht="SetCurrentPage('Uebersicht')"
    >
    </NavBar>
    <div :class="[this.Settings ? 'ContentHidden' : 'ContentVisible']">
      <QuizReport
        v-if="CurrentPage == 'QuizReport'"
        @MoveToQuiz="SetCurrentPage('Quiz')"
        @resetQuizValues="resetQuizValues()"
      ></QuizReport>
      <HomePage
        @MoveToQuiz="SetCurrentPage('Quiz')"
        @MoveToUebersicht="SetCurrentPage('Uebersicht')"
        v-if="CurrentPage == 'HomePage'"
      >
      </HomePage>
      <Quiz
        :CurrentPage="CurrentPage"
        :LastPage="LastPage"
        v-if="CurrentPage == 'Quiz'"
        @SettingsFalse="SetSettingsFalse()"
        @SettingsTrue="SetSettingsTrue()"
        @MoveToHomePage="SetCurrentPage('HomePage')"
        @MoveToUebersicht="SetCurrentPage('Uebersicht')"
        @MoveToQuizReport="SetCurrentPage('QuizReport')"
        @ChosenAnswer0="AntwortPrüfen(0)"
        @ChosenAnswer1="AntwortPrüfen(1)"
        @ChosenAnswer2="AntwortPrüfen(2)"
        @ChosenAnswer3="AntwortPrüfen(3)"
      >
      </Quiz>
      <Uebersicht
        v-if="CurrentPage == 'Uebersicht'"
        @MoveToQuiz="SetCurrentPage('Quiz')"
        @MoveToHomePage="SetCurrentPage('HomePage')"
      >
      </Uebersicht>
    </div>
  </div>
</template>

<style scoped>
@import "../public/edith-app.css";
</style>

<script>
window.screen.orientation.lock("portrait");
import HomePage from "./components/HomePage.vue";
import Quiz from "./components/Quiz.vue";
import Uebersicht from "./components/Uebersicht.vue";
import NavBar from "./components/NavBar.vue";
import QuizData from "./assets/signals.json";
import CurrentMode from "./components/CurrentMode.json";
import QuizReport from "./components/QuizReport.vue";
import SplashScreen from "./components/SplashScreen.vue";
addEventListener("backButton", BackButtonListener);
function BackButtonListener() {}

export default {
  data() {
    return {
      Splash: true,
      TestModus: "keine",
      CurrentPage: "none",
      LastPage: "",
      Settings: false,
      TestArrayOfQuestions: [],
    };
  },

  name: "App",
  components: {
    HomePage,
    Quiz,
    Uebersicht,
    NavBar,
    QuizReport,
    SplashScreen,
  },
  methods: {
    killSplash() {
      this.Splash = false;
      this.SetCurrentPage("HomePage");
    },
    get FragText() {
      return localStorage.getItem("FrageText");
    },
    set FrageText(value) {
      localStorage.setItem("FrageText", value);
    },
    get Filter() {
      return localStorage.getItem("Filter");
    },
    SetCurrentPage(Page) {
      HomePage.methods.resetFilter();
      this.LastPage = this.CurrentPage;
      this.CurrentPage = Page;
      console.log("new page : " + this.CurrentPage);
      console.log("recent page : " + this.LastPage);
    },
    GetCurrentPage() {
      return this.CurrentPage;
    },
    GetLastPage() {
      return this.LastPage;
    },
    SetSettingsFalse() {
      this.Settings = false;
    },
    SetSettingsTrue() {
      this.Settings = true;
    },

    GetQuestions() {
      let Mode = localStorage.getItem("Mode");
      let FilteredArray;
      if (this.Filter != "All") {
        FilteredArray = QuizData.filter(
          (Dependencies) => Dependencies.QuizFilter == this.Filter
        );
      } else {
        //FilteredArray = QuizData.filter((Dependencies) =>
        //  Dependencies.Filter.includes(this.Filter)
        //);
        // Hier weitermachen include geht noch nicht
        let BufferArray = QuizData.sort(() => Math.random() - 0.5);
        FilteredArray = BufferArray.filter(
          (Dependencies) => Dependencies.QuizFilter == BufferArray[0].QuizFilter
        );
      }
      console.log("ThisDotLog", FilteredArray);

      let FilteredArraySorted = FilteredArray.sort(() => Math.random() - 0.5);

      let FilteredArraySortedAndCutted;
      if (FilteredArraySorted.length > 4) {
        FilteredArraySortedAndCutted = FilteredArraySorted.slice(0, 4);
      } else {
        FilteredArraySortedAndCutted = FilteredArraySorted;
      }
      if (
        localStorage.getItem("Nummer") !=
        FilteredArraySortedAndCutted[0].LaufendeNummer
      ) {
        localStorage.setItem(
          "Nummer",
          FilteredArraySortedAndCutted[0].LaufendeNummer
        );
      } else {
        this.GetQuestions();
        return;
      }
      console.log(
        "on request LaufendeNummer: ",
        FilteredArraySortedAndCutted[0].LaufendeNummer
      );
      localStorage.setItem("Bild", FilteredArraySortedAndCutted[0].Bild);
      console.log("on request Bild: ", FilteredArraySortedAndCutted[0].Bild); // cast on int !!!!!
      localStorage.setItem(
        "Zuordnung",
        FilteredArraySortedAndCutted[0].QuizFilter
      );
      localStorage.setItem(
        "Bedeutung",
        FilteredArraySortedAndCutted[0].Bedeutung
      );
      localStorage.setItem(
        "Bezeichnung",
        FilteredArraySortedAndCutted[0].Bezeichnung
      );
      //get Anwers on the buttons @ the Quiz Page
      let FilteredArraySortedCuttedAndSortedAgain =
        FilteredArraySortedAndCutted.sort(() => Math.random() - 0.5);
      //möglickeit Stringify zu verwenden, aber unnötig viel Aufwand.
      console.error(FilteredArraySortedCuttedAndSortedAgain);
      localStorage.setItem(
        "TextButton1",
        FilteredArraySortedCuttedAndSortedAgain[0][Mode]
      );
      localStorage.setItem(
        "TextButton2",
        FilteredArraySortedCuttedAndSortedAgain[1][Mode]
      );
      localStorage.setItem(
        "TextButton3",
        FilteredArraySortedCuttedAndSortedAgain[2][Mode]
      );
      localStorage.setItem(
        "TextButton4",
        FilteredArraySortedCuttedAndSortedAgain[3][Mode]
      );
      if (Mode == "Bezeichnung") {
        localStorage.setItem(
          "FrageText",
          "Wie lautet die Bezeichnung für dieses Signal ?"
        );
      }
      if (Mode == "Bedeutung") {
        localStorage.setItem(
          "FrageText",
          "Wie lautet die Bedeutung dieses Signals ?"
        );
      }
    },

    AntwortPrüfen(PressedButton) {
      localStorage.setItem("Antwortend", "true");
      console.log(PressedButton);
      console.log(CurrentMode[0]);
      if (
        CurrentMode[0].AntwortenArray[PressedButton] ==
        CurrentMode[0].Bezeichnung
      ) {
        console.log("Wahr");
        CurrentMode[0].AntwortRichtig = true;
        if (this.Fortschritt < 95) {
          this.Fortschritt = this.Fortschritt + 5;
        } else {
          this.Fortschritt = 0;
          this.SetCurrentPage("QuizReport");
        }
        Quiz.methods.BarMovement(this.Fortschritt);
      } else {
        console.log("Falsch");
        CurrentMode[0].AntwortRichtig = false;
        if (this.Fortschritt > 0) {
          this.Fortschritt = this.Fortschritt - 5;
        }
        Quiz.methods.BarMovement(this.Fortschritt);
        CurrentMode[0].SummeFalscherAntworten =
          CurrentMode[0].SummeFalscherAntworten + 1;
      }
      if (CurrentMode[0].AntwortRichtig) {
        CurrentMode[0].NewHinweisText = "\nDas war richtig.\n";
      } else {
        CurrentMode[0].NewHinweisText =
          'Das war leider falsch.\nDie richtige Antwort ist: \n "' +
          CurrentMode[0].Bezeichnung +
          '"';
      }
      console.log("in der json steht: " + CurrentMode[0].AntwortRichtig);
    },
    resetQuizValues() {
      this.Fortschritt = 0;
    },
    setSelection(value) {
      CurrentMode.FragenAuswahl = value;
    },
    mounted() {
      localStorage.setItem("Filter", "All");
      localStorage.setItem("Mode", "Bedeutung");
      localStorage.setItem("UebersichtFilter", "All");
    },
  },
  emits: [
    "MoveToEinstellung",
    "MoveToUebersicht",
    "MoveToQuiz",
    "MoveToHomePage",
    "ChosenAnswer3",
    "ChosenAnswer2",
    "resetQuizValues",
    "ChosenAnswer1",
    "ChosenAnswer0",
    "SettingsTrue",
    "SettingsFalse",
  ],
  props: {},
};
</script>
