<style scoped>
.homeHolder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.gridHolder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.filterTag {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: gray;
  color: white;
  border-radius: 0.2rem;
  margin: 0 2rem;
}

.UnOrderedList {
  margin-top: 0;
  padding-left: 0rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100vw;
  height: 80%;
  overflow: hidden;
  scrollbar-width: 0;
}

.ListItem {
  background-color: white;
  padding: 0.5rem;
  height: 100%;
  border-radius: 0.2rem;
  line-break: loose;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media (orientation: landscape) {
  .UnOrderedList {
    grid-template-columns: repeat(6, 1fr);
    height: 50%;
  }

  .ListItem {
    font-size: 1.5rem;
    max-height: 70%;
  }

  .filterTag {
    font-size: 1.5rem;
  }
}

.OrderedList {
  height: auto;
  justify-content: left;
  width: 100%;
  align-content: center;
  list-style-type: none;
  padding: 0.3rem;
}

.quizHolder {
  padding: 1rem;
  height: 10rem;
  width: 10rem;
  background-color: white;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#blueCircle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  margin: 5%;
  border-radius: 50%;
  background-color: rgb(0, 66, 127);
}

.listHolder {
  height: 10rem;
  width: 10rem;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
}

.center {
  background-color: white;
  height: 70%;
  width: 70%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

@property --1 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --2 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --3 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --4 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --5 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 0%;
}

@property --6 {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}

/*Mobile*/
#my-pie-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  margin: 5%;
  border-radius: 50%;
  animation: aCircle 0s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  background: conic-gradient(
    rgb(0, 66, 127) var(--1) var(--2),
    green var(--2) var(--3),
    yellow var(--3) var(--4),
    red var(--4) var(--5),
    rgb(0, 66, 127) var(--5) var(--6)
  );
}

/*Web*/

#my-pie-chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  width: 90%;
  margin: 5%;
  border-radius: 50%;
  animation: aCircle 2s ease-in-out;
  animation-iteration-count: 1;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  background: conic-gradient(
    rgb(0, 66, 127) var(--1) var(--2),
    green var(--2) var(--3),
    yellow var(--3) var(--4),
    red var(--4) var(--5),
    rgb(0, 66, 127) var(--5) var(--6)
  );
}

@keyframes aCircle {
  from {
    --1: 0%;
    --2: 0%;
    --3: 0%;
    --4: 0%;
    --5: 0%;
    --6: 100%;
  }

  to {
    --1: var(--val1);
    --2: var(--val2);
    --3: var(--val3);
    --4: var(--val4);
    --5: var(--val5);
    --6: 100%;
  }
}

@media (orientation: landscape) {
  .homeHolder {
    flex-direction: row;
    vertical-align: center;
  }

  .quizHolder {
    height: 20rem;
    width: 20rem;
  }

  .listHolder {
    height: 20rem;
    width: 20rem;
  }

  .center {
    font-size: 3rem;
  }
}
</style>
<template>
  <div class="homeHolder" v-if="!this.Filter">
    <div class="quizHolder" @click="this.Filter = true">
      <div id="blueCircle">
        <div class="center">Quiz</div>
      </div>
    </div>
    <div class="listHolder" @click="$emit('MoveToUebersicht')">
      <div id="my-pie-chart">
        <div class="center">Liste</div>
      </div>
    </div>
  </div>
  <div class="homeHolder" v-if="this.Filter">
    <div class="gridHolder">
      <div class="filterTag">Bitte wähle eine Kategorie!</div>
      <ul class="UnOrderedList">
        <li class="OrderedList" v-for="(value, name) in this.FilterArray" :key="name">
          <div class="ListItem" @click="this.StartQuiz(name)">
            {{ value }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Signale from "../assets/signals.json";
import Quiz from "./Quiz.vue";
let FilterObject = {
  All: "Alle Signale",
  Wn: "Weichensignale",
  Hl: "Hl-Signale",
  Zs: "Zs-Signale",
  Lf: "Lf-Signale",
  ShTs: "Sh-Signale",
  HpVrKsHlMSo: "Haupt- und Vorsignale + Strecken- sorientierung",
  Ra: "Ra-Signale",
  Zp: "Zp-Signale",
  FzZg: "Signale an Fahrzeugen",
  El: "Fahrleitungs- signale",
  Ne: "Ne-Signale",
};
export default {
  data() {
    return {
      FilterArray: Object.values(FilterObject),
      Filter: false,
      blue: 0,
      green: 0,
      yellow: 0,
      red: 0,
    };
  },

  name: "HomePage",
  props: {},
  components: {},
  methods: {
    StartQuiz(index) {
      Quiz.methods.resetFortschritt();
      window.localStorage.Filter = Object.keys(FilterObject)[index];
      this.$emit("MoveToQuiz");
    },
    getFortschritt() {
      this.blue = 0;
      this.green = 0;
      this.yellow = 0;
      this.red = 0;
      let nSignals = 0;
      for (const LaufendeNummer in Signale) {
        nSignals++;
        if (Number(localStorage.getItem(parseInt(LaufendeNummer))) > 6) {
          this.red = this.red + 1;
        } else if (Number(localStorage.getItem(parseInt(LaufendeNummer))) > 3) {
          this.yellow = this.yellow + 1;
        } else if (Number(localStorage.getItem(parseInt(LaufendeNummer))) > 0) {
          this.green = this.green + 1;
        } else {
          this.blue = this.blue + 1;
        }
      }
      let val1 = 0;
      let val2 = (this.blue / nSignals) * 100;
      let val3 = (this.green / nSignals) * 100 + val2;
      let val4 = (this.yellow / nSignals) * 100 + val3;
      let val5 = (this.red / nSignals) * 100 + val4;

      var r = document.querySelector(":root");
      r.style.setProperty("--val1", val1 + "%");
      r.style.setProperty("--val2", val2 + "%");
      r.style.setProperty("--val3", val3 + "%");
      r.style.setProperty("--val4", val4 + "%");
      r.style.setProperty("--val5", val5 + "%");
    },
    resetFilter() {
      //Does not work ->should change Filter back to false at click on Home to close the Filter view
      this.Filter = false;
    },
  },
  created() {
    this.getFortschritt();
    localStorage.Filter = "All";
  },

  emits: ["MoveToQuiz", "MoveToUebersicht"],
};
</script>
