<template>
  <div class="frontPage">
    <img class="logo" src="../../public/LOGO.png" />
  </div>
</template>
<style scoped>
.frontPage {
  z-index: 1000;
  position: static;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgb(244, 244, 244);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 100%;
  animation: fadein ease 1300ms;
  scale: 100%;
}

@keyframes fadein {
  from {
    scale: 10%;
  }

  to {
    scale: 100%;
  }
}
</style>
<script>
export default {
  name: "SplashScreen",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {
    window.setTimeout(() => this.$emit("killSplash"), 2000);
  },

  emits: ["killSplash"],
};
</script>
