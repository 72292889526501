<template>
  <div class="PageHolder" :class="[this.focus ? 'lockBackground' : '']">
    <div class="FilterHolder" style="background-color: grey">
      <div class="icon">
        <i
          v-if="!this.filterDrop"
          @click="this.filterDrop = true"
          class="fa-solid fa-filter"
        ></i>
        <i
          v-if="this.filterDrop"
          @click="this.filterDrop = false"
          class="fa-solid fa-filter-circle-xmark"
        >
        </i>
        <div>{{ this.Filter }}</div>
        <div v-if="this.filterDrop" class="FilterDrop">
          <li
            class="FilterHolder"
            v-for="item in this.FilterArray"
            :key="item"
            @click="
              this.Filter = item;
              this.filterDrop = false;
            "
          >
            <div class="FilterElement">{{ item }}</div>
          </li>
        </div>
      </div>
      <div class="inputHolder">
        <input
          class="suchFeld"
          type="text"
          v-model="this.SearchText"
          @keyup="this.Searchbar()"
          placeholder="Suche"
        />
        <div
          class="deleteInput"
          @click="
            this.SearchText = '';
            this.Searchbar();
          "
        >
          <i class="fa-regular fa-circle-xmark"></i>
        </div>
      </div>
    </div>
    <div class="Grid">
      <ul class="UnOrderedList">
        <li
          class="OrderedList"
          v-for="item in this.anzObj"
          :id="'Zeichen-' + item.Bild"
          :key="item.Bezeichnung"
          v-show="item.Zuordnung == this.Filter || this.Filter == 'All'"
        >
          <div
            :class="[
              item == CurrentSignal ? 'getMoreInfo' : '',
              this.TestForPrio(item) > 6
                ? 'red'
                : '' || this.TestForPrio(item) > 3
                ? 'yellow'
                : '' || this.TestForPrio(item) > 0
                ? 'green'
                : '' || typeof this.TestForPrio(item) != 'string'
                ? 'blue'
                : '',
            ]"
            class="ListItem"
            @click="caller(item)"
          >
            <div v-if="item == CurrentSignal" class="closeButton">
              <i class="fa-regular fa-circle-xmark"></i>
            </div>
            <img
              class="Bild"
              :src="require(`../assets/images/signals/${item.Bild}.jpg`)"
            />
            <div v-if="item == CurrentSignal">
              <div class="Row">{{ item.Bezeichnung }}</div>
              <div class="Row">{{ item.Bedeutung }}</div>
              <div class="Row">Lernstand = {{ this.TestForPrio(item) }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.deleteInput {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgb(180, 0, 0);
  margin-right: 0.2rem;
}

.inputHolder {
  width: 70%;
  display: flex;
  flex-direction: row;
  background-color: white;
  margin: 0.2rem;
  border-radius: 0.2rem;
}

.FilterElement {
  padding: 0.5rem;
  margin: 0 1rem;
}

.FilterHolder:nth-child(2n) {
  background-color: rgb(240, 240, 240);
}

.FilterHolder {
  padding: 0 1rem;
}

.FilterDrop {
  position: fixed;
  color: black;
  left: 0;
  margin-top: 2px;
  background-color: rgb(220, 220, 220);
  font-size: 1rem;
  list-style-type: none;
  height: 50vh;
  overflow-y: scroll;
  box-shadow: gray 5px 10px 10px;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.PageHolder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.5rem;
  width: 4rem;
  color: white;
}

.FilterHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
}

.UnOrderedList {
  margin-top: 0;
  padding-left: 0rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/*tp prevent the keyboard messing things up*/
@media (orientation: landscape) {
  .UnOrderedList {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ListItem {
  background-color: white;
  display: flexbox;
  padding: 0.5rem;
  height: 100%;
  border-radius: 0.2rem;
  border-bottom: solid;
}

.OrderedList {
  height: auto;
  justify-content: left;
  width: 100%;
  align-content: center;
  list-style-type: none;
  padding: 0.3rem;
}

.red {
  border-bottom-color: red;
  border-bottom-width: 0.3rem;
}

.yellow {
  border-bottom-color: yellow;
  border-bottom-width: 0.3rem;
}

.green {
  border-bottom-color: green;
  border-bottom-width: 0.3rem;
}

.blue {
  border-bottom-color: rgb(0, 66, 127);
  border-bottom-width: 0.3rem;
}

.Bild {
  width: 100%;
}

.Row {
  margin-bottom: 0.5rem;
  font-size: inherit;
}

.getMoreInfo {
  box-shadow: 10px 10px 10px gray;
  height: min-content;
  position: absolute;
  top: 33%;
  right: 15%;
  width: 70%;
  animation-name: example;
  animation-duration: 4s;
  font-size: 0.8rem;
  pointer-events: all;
}

/*Web*/
/*
@media (orientation: landscape) {
  .getMoreInfo {
    right: 33%;
    width: 33%;
    font-size: 1.2rem;
  }
}
*/
.suchFeld {
  font-size: 1rem;
  border: none;
  width: 100%;
  border-radius: 0.1rem;
  user-select: text;
  -moz-user-select: -moz-none;
  outline: none;
}

.Grid {
  width: 100%;
  overflow-y: scroll;
  scrollbar-width: 0px;
}

.lockBackground {
  pointer-events: none;
}

.closeButton {
  position: absolute;
  height: 2rem;
  width: 2rem;
  right: 0.5rem;
  color: rgb(180, 0, 0);
  font-size: 2rem;
}
</style>
<script>
import Signals from "../assets/signals.json";
export default {
  data() {
    return {
      anzObj: [],
      FilterArray: [
        "All",
        "Wn",
        "Ks",
        "Bü",
        "M",
        "Hl",
        "Zs",
        "Lf",
        "Sh",
        "Hp",
        "Fz",
        "Zg",
        "Ra",
        "Zp",
        "So",
        "Vr",
        "Ts",
        "El",
        "Ne",
        "O",
      ],
      Pos1: 0,
      FilterVisible: false,
      items: Signals,
      Filter: "All",
      CurrentSignal: "",
      SearchItems: [],
      SearchText: "",
      filterDrop: false,
      focus: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.focus = false;
  },
  methods: {
    handleScroll() {
      // alert(event); event as argument
    },
    TestForPrio(item) {
      return localStorage.getItem(parseInt(item.LaufendeNummer));
    },
    caller(value) {
      this.focus = !this.focus;
      if (this.CurrentSignal == value) {
        this.CurrentSignal = "";
      } else {
        this.CurrentSignal = value;
      }
    },

    ReturnFilterdArray() {
      if (this.Filter != "All") {
        const HP = Signals.filter(
          (Dependencies) => Dependencies.Zuordnung == this.Filter
        );
        return HP;
      } else {
        return Signals;
      }
    },
    filterdown() {
      var ArLength = this.FilterArray.length;
      if (this.Pos1 < ArLength - 1) {
        this.Pos1++;
      } else {
        this.Pos1 = 0;
      }
      this.Filter = this.FilterArray[this.Pos1];
      console.error("Filter is Now : " + this.Filter);
    },
    filterup() {
      var ArLength = this.FilterArray.length;
      if (this.Pos1 > 0) {
        this.Pos1--;
      } else {
        this.Pos1 = ArLength - 1;
      }
      this.Filter = this.FilterArray[this.Pos1];
      console.error("Filter is Now : " + this.Filter);
    },
    Searchbar() {
      if (this.SearchText == "") {
        this.anzObj = Signals;
        return;
      }
      const HP = Signals.filter(
        (Dependencies) =>
          Dependencies.Zuordnung.toLocaleLowerCase().includes(
            this.SearchText.toLocaleLowerCase().trim()
          ) ||
          Dependencies.Bedeutung.toLocaleLowerCase().includes(
            this.SearchText.toLocaleLowerCase().trim()
          ) ||
          Dependencies.Bezeichnung.toLocaleLowerCase().includes(
            this.SearchText.toLocaleLowerCase().trim()
          )
      );
      this.anzObj = HP;
    },
  },

  components: {},
  mounted() {
    this.anzObj = Signals;
  },
};
</script>
